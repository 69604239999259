import React from 'react'
import { Container } from 'react-bootstrap'
import LayoutCustom from "../components/LayoutCustom";
import Footer from "../components/Footer";

const NotFound = () => {
	return (
		<LayoutCustom>
			<div className="main-content-padding">
				<div className="app-section-404">
					<Container>
						<div className="app-404-detail">
							<div className="app-404-detail-content">
								<h2>404</h2>
								<h6>The page you are looking for was not found.</h6>
							</div>
						</div>
					</Container>
					<div className="footer-custom">
						<Footer />
					</div>
				</div>
			</div>
		</LayoutCustom>
	)
}

export default NotFound
